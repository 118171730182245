#nav-bar {
  position: fixed;
  top: 00;
  z-index: 10;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  background: #ffffffa8;
  color: #0f2c67;
  width: 100%;
}
.desktop-view {
  display: contents;
}
.mobile-view {
  display: none;
}
a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #cd1818;
}
a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ftco-navbar-light {
  z-index: 3;
  padding: 0;
}
.nav-backgroung-maxhight {
  background: #ffffff !important;
  transition: 0.5s;
  height: 80px;
}
.nav-backgroung-minhight {
  background: #ffffff !important;
  height: 50px;
  transition: 0.5s;
}
.maximage-size {
  height: 55px;
  transition: 0.5s;
}
.minimg-size {
  height: 35px;
  transition: 0.5s;
}
.min-size-text {
  font-size: 14px;
  font-weight: 800;
  font-family: "Noway", serif;
  color: #0f2c67;
  /* line-height: 13px; */
  padding-top: 16px;
  padding-left: 2px;
  transition: 0.5s;
}
.max-size-text {
  padding-top: 26px;
  padding-left: 5px;
  font-size: 26px;
  font-weight: 800;
  font-family: "Noway", serif;
  color: #0f2c67;
  transition: 0.5s;
}
@media (max-width: 991.98px) {
  .ftco-navbar-light {
    background: rgb(255, 255, 255) !important;
    /* position: relative;
    top: 0; */
    /* padding: 10px 15px; */
  }
}
@media (max-width: 991.98px) {
  .ftco-navbar-light .navbar-nav {
    padding-bottom: 10px;
  }
}
.ftco-navbar-light .navbar-nav > .nav-item .nav-link {
  font-size: 14px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  color: #0f2c67;
  text-transform: uppercase;
  position: relative;
  opacity: 1 !important;
}
.ftco-navbar-light .navbar-nav > .nav-item .nav-link {
  font-size: 14px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  color: #0f2c67;
  text-transform: uppercase;
  position: relative;
  opacity: 1 !important;
}
.ftco-navbar-light .navbar-nav > .nav-item .nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: #cd1818;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  z-index: -1;
}
.ftco-navbar-light .navbar-nav > .nav-item .nav-link:hover {
  color: #fff;
}
.ftco-navbar-light .navbar-nav > .nav-item .nav-link:hover:before {
  visibility: visible;
  background-color: #cd1818;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
@media (max-width: 991.98px) {
  .ftco-navbar-light .navbar-nav > .nav-item .nav-link {
    padding-left: 10px;
    padding-right: 0;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    color: #0f2c67;
  }
  .ftco-navbar-light .navbar-nav > .nav-item .nav-link:hover {
    color: #000000;
  }
  .ftco-navbar-light .navbar-nav > .nav-item .nav-link:hover:before {
    display: none;
  }
}
.dropdown_auto .dropdown-menu {
  border: none;
  background: #fff;
  box-shadow: 0px 10px 34px -20px #00000069;
  border-radius: 4px;
  float: left;
  width: 66rem;
  padding-top: 0px;
  padding-bottom: 1px;
}

.dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000 !important;
}
.dropdown-title {
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #000 !important;
  text-decoration: underline;
}
.dropdown_auto:hover .dropdown_auto_menu {
  display: block;
  right: -15rem;
}
.dropdown_auto_menu .dropdown-item:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.dropdown_auto_menu .dropdown-item:hover {
  background-color: #f90716 !important;
  color: #fff !important;
  transition: 0.3s;
}
@media (max-width: 991.98px) {
  .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu {
    /* display: contents !important; */
    background: #ffffff;
  }
  .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item {
    color: rgba(255, 255, 255, 0.8);
  }
}

.ftco-navbar-light .navbar-nav > .nav-item.active > a {
  color: #fff;
}

.ftco-navbar-light .navbar-toggler {
  border: none;
  color: #ffffff80 !important;
  cursor: pointer;
  padding-right: 0;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.1em;
}
.ftco-navbar-light .navbar-toggler:focus {
  outline: none !important;
}

.navbar-header {
  color: #2f75b5;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
  /* border-bottom: 1px solid #2F75B5; */
}
.navbar-header::after {
  content: "";
  position: absolute;
  width: 50%;
  border-bottom: 3px dashed #2f75b5;
  bottom: 0;
  left: 25%;
}
.oil-gas {
  background: #f2f2f2;
  height: 24rem;
}
@media screen and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    background-color: #fff;
    top: 100%;
    right: 0;
    width: 100%;
  }
  .dropdown_auto .dropdown-menu {
    background: transparent;
    width: 100%;
    box-shadow: unset;
    box-shadow: unset;
    border-radius: unset;
    float: unset;
    padding-top: unset;
    padding-bottom: unset;
    height: 50vh;
    overflow-y: auto;
  }

  .dropdown-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .dropdown_auto:hover .dropdown_auto_menu {
    right: 0;
    /* display: none !important; */
  }
  .dropdown_auto_menu .dropdown-item:hover:after {
    transform: scaleX(0);
    transform-origin: none;
  }
  .oil-gas {
    height: fit-content;
  }
}

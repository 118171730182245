@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('./../public/font/archia-regular-webfont.ttf'); */
/* @import url('./../public/font/noway_regular_italic-webfont.ttf');
@import url('./../public/font/noway-regular-webfont.ttf'); */
@font-face {
  font-family: Archia;
  src: url(./../public/font/archia-regular-webfont.ttf);
}
@font-face {
  font-family: Noway;
  src: url(./../public/font/noway-regular-webfont.ttf);
}
.App {
  text-align: center;
}
body{
/* background-image: 
  url("./../public/assets/image/1.jpg"); */
  background-color: #fff;
}

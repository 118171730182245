
.navbar-space{
    margin-bottom: 80px;
}
.slider-img{
    width: 100%;
    height: 450px;
}
.carousel-caption {
  bottom: 50% !important;
  z-index: 2 !important;
  text-align: center !important;
}
.animate__delay-0_5s {
  animation-delay: 0.2s;
}
.carousel-caption h2 {
  right: 0% !important;
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.mml-title {
  font-family: 'Noway', serif;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000000;
}
.about-us-box {
  width: 350px;
  height: 300px;
  margin: 0px 0 0 0px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morph 8s ease-in-out infinite;
  box-shadow: 5px 10px 18px #888888;
}

@keyframes morph {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  25% {
    border-radius: 43% 57% 35% 65% / 36% 35% 65% 64%;
  }

  50% {
    border-radius: 16% 84% 35% 65% / 17% 35% 65% 83%;
  }

  75% {
    border-radius: 79% 21% 55% 45% / 76% 64% 36% 24%;
  }

  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

.aboutus-description {
  font-family: 'Noway', serif;
  font-size: 18px;
  font-weight: 600;
  text-align: justify;
}

.button-style-2 {
  position: relative;
  display: inline-block;
  outline: none;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.button-style-2 {
  width: 10rem;
  height: auto;
}
.button-style-2 .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2rem;
  height: 2rem;
  background: #0f2c67;
  border-radius: 1.625rem;
}
.button-style-2 .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.button-style-2 .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.2rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.button-style-2 .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
.button-style-2 .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.25rem 1rem;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
}
.button-style-2:hover .circle {
  width: 100%;
}
.button-style-2:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
.button-style-2:hover .button-text {
  color: #fff;
}
.image-style-1{
  width: 350px;
  height: 350px;
  transition: 0.5s;

}
.image-block{
  overflow: hidden;
  border-radius: 50%;
  width: 350px;
  height: 350px;
  box-shadow: 0 5px 20px #fbff00d0;
}
.image-block:hover .image-style-1{
  transform: scale(1.1);
  transition: 0.5s;
}
.about-us-title{
  font-family: 'Noway', sans-serif;
  font-weight: 700;
  font-size: 50px;
  margin-top: 20px;
  text-transform: uppercase;
}
.carousel-image{
  width: 100%;
  height: 400px;
}
.product-list-image{
  width: 100px;
  height: 100px;
}
.card-style{
  width: 300px;
  height: 300px;
}
.card-style > .card-body > .image-box{
  background-image: url('./../../../public/assets/image/products/product-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  /* width: 540px; */
}
.image-box img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 250px;
  transition: 2s;
}

.card-style:hover .image-box img {
  transform: scale(1.5);
  transition: 2s;
}

@media screen and (max-width: 760px){
  .slider-img{
    width: 100%;
    height: 250px;
  }
};
  
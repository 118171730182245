.services-bg{
    background-image: linear-gradient(#000000e1, #000000e1),
    url("./../../../public/assets/image/background/services.jpg");
    width: auto;
    height: 380px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
.geological_service_text{
  font-family: 'Noway', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

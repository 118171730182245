@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap");
.product-bg {
  background-image: linear-gradient(#000000e1, #000000e1),
    url("./../../../public/assets/image/background/products.jpg");
  width: auto;
  height: 380px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.product-name {
  font-family: "Noway", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #000000;
  text-transform: uppercase;
}
.product-text {
  font-family: "Noway", sans-serif;
  font-size: 16px;
  text-align: justify;
  font-weight: 600;
}
.brochures-download {
  font-family: "Noway", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
  /* background-color: #ff0000 !important; */
}
.brochures-download:hover {
  background: #f01717;
  color: #f01717;
  text-decoration: none;
  transition: 0.5s;
}
.brochures-download:hover span {
  padding-right: 20px;
}
.brochures-download span:after {
  padding-left: 20px;
  content: "Download";
  opacity: 0;
  top: 0;
  right: -20px;
  /* transition: 0.5s; */
}
.brochures-download:hover span:after {
  opacity: 1;
  right: 0;
}

.product-block{
    width: 100%;
    background-color: #182747;
}
.product-block-title{
    font-family: "Noway", sans-serif;
    font-weight: 700;
    font-size: 35px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    padding-top: 2rem;
}
.product-image{
    padding-bottom: 3rem;
}
.product-description-name{
    font-family: "Noway", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 2rem;
}
.product-details-image{
    width: 10rem;
    /* height: 13rem; */
}
.product-key-point-title{
    font-family: "Noway", sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.product-key-point-list{
    font-family: "Noway", sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.brochures-name{
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

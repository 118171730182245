
.brochures-header {
    background-color: #fa9494;
  }
  .brochures-title {
    color: #fff;
    font-family: "Noway", sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
.eleven h2 {
    line-height: 1.5em;
  }
  
  .eleven h2:before {
    position: absolute;
    left: 0;
    top: 105px;
    width: 60%;
    left: 50%;
    margin-left: -30%;
    height: 3px;
    content: "";
    background-color: #ffffff;
    z-index: 4;
  }
  .eleven h2:after {
    position: absolute;
    width: 25px;
    height: 35px;
    left: 50%;
    margin-left: -20px;
    top: 83px;
    content: "\00a7";
    font-size: 30px;
    line-height: 25px;
    color: #ffd372;
    font-weight: 500;
    z-index: 5;
    display: block;
    background-color: #fff;
  }
  
  .seven h2 {
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 10px;
    align-items: center;
  }
  
  .seven h2:after,
  .seven h2:before {
    content: " ";
    display: block;
    border-bottom: 3px solid #ffffff;
    border-top: 3px solid #ffffff;
    height: 10px;
    background-color: transparent;
  }

